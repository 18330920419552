import { useListContext, Loading } from "react-admin";
import Task from "./Task";
import { List as MuiList, ListSubheader, Collapse } from "@mui/material";
import { formatRelative, parseISO, compareAsc } from "date-fns";
import { useCustomLocale } from "../hooks/useCustomLocale";
import { TransitionGroup } from "react-transition-group";

/**
 * This component lists tasks using custom Material UI based components.
 * @returns {React.Component}
 */
function TaskListCustomlist() {
  const { data, isLoading } = useListContext();
  const { locale } = useCustomLocale();

  if (isLoading) return <Loading />;

  const tasksByDate = data.reduce((accumulator, task) => {
    if (!Array.isArray(accumulator[task.date_end]))
      accumulator[task.date_end] = [];
    accumulator[task.date_end].push(task);
    return accumulator;
  }, {});

  return (
    <MuiList>
      <TransitionGroup>
        {Object.entries(tasksByDate).map(([date_end, tasks]) => (
          <Collapse key={date_end}>
            <>
              <ListSubheader>
                {compareAsc(parseISO(date_end), new Date()) === 1
                  ? "Due "
                  : "Overdue "}
                {formatRelative(parseISO(date_end), new Date(), {
                  locale,
                })}
              </ListSubheader>
              <TransitionGroup>
                {tasks.map((task) => (
                  <Collapse key={task.id}>
                    {/* TODO: use a record context instead of passing record */}
                    <Task record={task} />
                  </Collapse>
                ))}
              </TransitionGroup>
            </>
          </Collapse>
        ))}
      </TransitionGroup>
    </MuiList>
  );
}
export { TaskListCustomlist };
