import { useRecordContext, useUpdate, useNotify } from "react-admin";
import { MenuItem, Select as MuiSelect } from "@mui/material";

function StatusInput({ options = [] }) {
  const record = useRecordContext();
  const [update] = useUpdate();
  const notify = useNotify();

  const handleChange = (e) => {
    update(
      "tasks",
      {
        id: record.id,
        data: { status: e.target.value },
        previousData: record,
      },
      {
        onError: (err) => {
          console.log("error trying to complete task", err);
          notify(
            "There was an issue marking the task as complete. Please try again later."
          );
        },
      }
    );
  };

  return (
    <MuiSelect value={record.status} variant="standard" onChange={handleChange}>
      {options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.displayValue}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

export { StatusInput };
