import { Auth } from "aws-amplify";

const awsconfig = {
  Auth: {
    region: `${process.env.REACT_APP_REGION}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_USER_POOL_APP_CLIENT}`,
    oauth: {
      domain: `${process.env.REACT_APP_USER_POOL_DOMAIN}`,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${process.env.REACT_APP_TRACE_DOMAIN}`,
      redirectSignOut: `${process.env.REACT_APP_TRACE_DOMAIN}/loggedout`,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "TraceAPI",
        endpoint: process.env.REACT_APP_TRACE_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization:
              process.env.REACT_APP_AUTH_MODE === "test"
                ? process.env.REACT_APP_TEST_USER_TOKEN
                : (await Auth.currentSession()).getIdToken().getJwtToken(),
          };
        },
      },
    ],
  },
};
export default awsconfig;
