// Based on this project: https://github.com/haldarmahesh/use-mobile-detect-hook/blob/master/src/index.ts
import { useStore } from "react-admin";

const getMobileDetect = (userAgent) => {
  const isAndroid = () => !!userAgent.match(/Android/i);
  const isIos = () => !!userAgent.match(/iPhone|iPad|iPod/i);
  const isOpera = () => !!userAgent.match(/Opera Mini/i);
  const isWindows = () => !!userAgent.match(/IEMobile/i);
  const isSSR = () => !!userAgent.match(/SSR/i);

  const isMobile = () => !!(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => !!(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};

const useMobileDetect = () => {
  const [addToHomeOpen, setAddToHomeOpen] = useStore("addToHome.open", false);
  const isStandalone = navigator?.standalone;

  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  return {
    addToHomeOpen,
    setAddToHomeOpen,
    isStandalone,
    ...getMobileDetect(userAgent),
  };
};

export default useMobileDetect;
