import { forwardRef } from "react";
import { UserMenu as RaUserMenu, Logout, useUserMenu } from "react-admin";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import useMobileDetect from "../hooks/useMobileDetect";

function UserMenu(props) {
  return (
    <RaUserMenu {...props}>
      <AddToHomeScreenPromptMenu />
      <Logout redirectTo="/loggedout" />
    </RaUserMenu>
  );
}
export default UserMenu;

const AddToHomeScreenPromptMenu = forwardRef((props, ref) => {
  const { isIos, isStandalone, setAddToHomeOpen } = useMobileDetect();
  const { onClose } = useUserMenu();

  if (!isIos() || isStandalone) {
    return null;
  }

  return (
    <MenuItem
      ref={ref}
      {...props}
      onClick={(event) => {
        setAddToHomeOpen(true);
        onClose();
      }}
    >
      <ListItemIcon>
        <AddToHomeScreenIcon />
      </ListItemIcon>
      <ListItemText>Add to Home Screen</ListItemText>
    </MenuItem>
  );
});
