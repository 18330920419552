import { useEffect } from "react";
import { useLogin } from "react-admin";
import Layout from "./Layout";

export default function LoginPage() {
  const login = useLogin();

  useEffect(() => {
    login();
  }, [login]);

  return (
    <Layout appBar={() => <></>} menu={() => <></>}>
      Taking you to your federated sign-in...
    </Layout>
  );
}
