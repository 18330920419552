import { Link, Layout } from "react-admin";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";

export default function LoggedOutPage() {
  console.log("logged out page");

  return (
    <Layout appBar={() => <></>} menu={() => <></>}>
      <Card variant="elevation">
        <CardHeader
          title="Logged Out"
          subheader="This will show your logged in status."
          avatar={
            <Avatar sx={{ bgcolor: "red" }}>
              <LockOpenTwoToneIcon color="success" />
            </Avatar>
          }
        />

        <p>You have been successfully logged out.</p>
        <p>
          <Link to="/login">Log in again?</Link>
        </p>
      </Card>
    </Layout>
  );
}
