import { Show } from "react-admin";
import { TaskShowView } from "./TaskShowView";
import { Box } from "@mui/material";

export const TaskShow = () => {
  return (
    <Show title="My Task List" component={Box}>
      <TaskShowView />
    </Show>
  );
};
