const traceTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#ff5a5c",
      light: "#ff8e89",
      dark: "#c62032",
    },
    secondary: {
      main: "#5c7688",
      light: "#8aa5b8",
      dark: "#304a5b",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "'Montserrat', sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
};

export default traceTheme;
