import { useEffect } from "react";
import useMobileDetect from "../hooks/useMobileDetect";
import {
  Drawer,
  Card,
  CardContent,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";

export default function AddToHomePrompt() {
  const { isIos, isStandalone, addToHomeOpen, setAddToHomeOpen } =
    useMobileDetect();

  useEffect(() => {
    if (!isStandalone && isIos() && !localStorage.getItem("addToHomePrompt")) {
      setTimeout(() => setAddToHomeOpen(true), 2000);
    }
  }, [isIos, isStandalone, setAddToHomeOpen]);

  // If it is not iOS, or if the iOS device is in standalone mode already, don't render the component
  if (!isIos() || isStandalone) {
    return null;
  }

  const closeHandler = () => {
    setAddToHomeOpen(false);
    localStorage.setItem("addToHomePrompt", new Date().toISOString());
  };

  return (
    <Drawer
      anchor="bottom"
      open={addToHomeOpen}
      onClose={closeHandler}
      PaperProps={{
        elevation: 6,
        square: false,
      }}
    >
      <Card>
        <CardHeader
          title="Add to home screen"
          titleTypographyProps={{ color: "primary" }}
          action={
            <IconButton aria-label="close" onClick={closeHandler}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box sx={{ mb: 2, mx: 2 }}>
            <Typography variant="body2" color="text.secondary">
              This website has app functionality. Add it to your home screen to
              use it in fullscreen as an app.
            </Typography>
          </Box>
          <Divider variant="middle" />
          <List>
            <ListItem>
              <ListItemIcon>
                <IosShareIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    Tap the <em>Share</em> button
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AddBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    Tap <em>Add to Home Screen</em>
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Drawer>
  );
}
