import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route, BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import dataProvider from "./providers/dataProvider";
import getAuthProvider from "./providers/amplifyAuthProvider";
import awsconfig from "./config/awsconfig";
import traceTheme from "./theme/theme";
import LoginPage from "./theme/LoginPage";
import Layout from "./theme/Layout";
import LoggedOutPage from "./theme/LoggedOutPage";
import TaskList from "./tasks/TaskList";
import { TaskShow } from "./tasks/TaskShow";

Amplify.configure(awsconfig);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const authProvider = getAuthProvider(Auth);

function App() {
  return (
    <>
      <BrowserRouter>
        <Admin
          layout={Layout}
          theme={traceTheme}
          authProvider={authProvider}
          dataProvider={dataProvider}
          loginPage={LoginPage}
        >
          <Resource
            name="tasks"
            list={TaskList}
            options={{ label: "My Task List" }}
            show={TaskShow}
          />
          <Resource name="projects" />
          <CustomRoutes noLayout>
            <Route path="/loggedout" element={<LoggedOutPage />} />
          </CustomRoutes>
        </Admin>
      </BrowserRouter>
    </>
  );
}

export default App;
