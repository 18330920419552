import { stringify } from "query-string";
import { isEqual } from "lodash";
import { HttpError } from "react-admin";
import { API } from "aws-amplify";

// Setting Amplify API client to give entire response (include headers) instead of just body
const httpClientInit = {
  response: true,
};
const apiName = "TraceAPI";

const dataProvider = {
  getList: async (resource, params = {}) => {
    console.log(`getList ${resource}`);

    const page = params?.pagination?.page || 1;
    const perPage = params?.pagination?.perPage || 10;

    const query = {
      offset: (page - 1) * perPage + 1,
      limit: perPage,
      ...params.filter,
    };

    if (params.sort) {
      // translate from react-admin id to resource id
      if (params.sort && params.sort.field === "id") {
        params.sort.field = "id";
      }
      query.sort = params.sort.field;
      query.direction = params.sort.order.toLowerCase();
    }

    if (params.scripts) {
      query.scripts = JSON.stringify([
        {
          name: params.scripts.name ? params.scripts.name : "",
          phase: params.scripts.phase ? params.scripts.phase : "",
          param: params.scripts.param ? params.scripts.param : "",
        },
      ]);
    }

    const url = getResourceURL(resource, "getList", params, query);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.get(apiName, url, httpClientInit);
      const data = response.data.map((item) => ({
        ...item,
        id: item.id,
      }));

      return {
        data: data,
        total: parseInt(response.headers["x-total-count"]),
      };
    } catch (err) {
      handleError(err);
    }
  },

  getOne: async (resource, params) => {
    console.log(`getOne ${resource}`);

    const url = getResourceURL(resource, "getOne", params);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.get(apiName, url, httpClientInit);

      return {
        data: {
          ...response.data,
          id: response.data.id,
        },
      };
    } catch (err) {
      handleError(err);
    }
  },

  getMany: async (resource, params) => {
    console.log(`getMany ${resource}`);

    const ids = params.ids.map((id) => `ids=${id}`);
    const limit = 9999; // trying to just get all records
    const query = `limit=${limit}&ids=&${ids.join("&")}`;
    // Extra empty ids= is to trigger Express to treat as array
    // Express 5 and Express API validator shortcoming that hopefully will be fixed at some point

    const url = getResourceURL(resource, "getMany", params, query);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.get(apiName, url, httpClientInit);

      const data = Array.isArray(response.data)
        ? response.data.map((item) => ({
            ...item,
            id: item.id,
          }))
        : [
            {
              ...response.data,
              id: response.data.id,
            },
          ];

      return {
        data: data,
      };
    } catch (err) {
      handleError(err);
    }
  },

  getManyReference: async (resource, params = {}) => {
    console.log(`getManyReference ${resource}`);

    const page = params?.pagination?.page || 1;
    const perPage = params?.pagination?.perPage || 10;

    const query = {
      offset: (page - 1) * perPage + 1,
      limit: perPage,
      [params.target]: params.id,
      ...params.filter,
    };

    if (params.sort) {
      // translate from react-admin id to resource id
      if (params.sort && params.sort.field === "id") {
        params.sort.field = "id";
      }
      query.sort = params.sort.field;
      query.direction = params.sort.order.toLowerCase();
    }

    if (params.scripts) {
      query.scripts = JSON.stringify([
        {
          name: params.scripts.name ? params.scripts.name : "",
          phase: params.scripts.phase ? params.scripts.phase : "",
          param: params.scripts.param ? params.scripts.param : "",
        },
      ]);
    }

    const url = getResourceURL(resource, "getManyReference", params, query);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.get(apiName, url, httpClientInit);

      const data = response.data.map((item) => ({
        ...item,
        id: item.id,
      }));
      return {
        data: data,
        total: parseInt(response.headers["x-total-count"]),
      };
    } catch (err) {
      handleError(err);
    }
  },

  update: async (resource, params) => {
    console.log("Update", resource, params);

    const previousData = params.previousData;

    // Only send fields that were changed and the record_id if there is a previousData
    const data = previousData
      ? {
          record_id: params.data.record_id,
          ...diff(params.data, params.previousData),
        }
      : params.data;

    const url = getResourceURL(resource, "update", params);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.patch(apiName, url, {
        ...httpClientInit,
        body: data,
      });

      return { data: { ...response.data, id: response.data.id } };
    } catch (err) {
      handleError(err);
    }
  },

  create: async (resource, params) => {
    const url = getResourceURL(resource, "create");

    try {
      const response = await API.post(apiName, url, {
        ...httpClientInit,
        body: params.data,
      });

      return {
        data: { ...response.data, id: response.data.id },
      };
    } catch (err) {
      handleError(err);
    }
  },

  delete: async (resource, params) => {
    const url = getResourceURL(resource, "delete", params);

    try {
      // Need to use axios instead of Amplify when testing without SAML auth
      const response = await API.del(apiName, url, httpClientInit);

      return { data: { id: response.id } };
    } catch (err) {
      handleError(err);
    }
  },

  getConfigCodes: async () => {
    const response = await API.get(apiName, "/config", httpClientInit);
    return { data: response.data.codes };
  },

  // Don't use the entire config object, just the ones we're actually using.
  // Rename to snakecase property names and reformat data if necessary.
  getConfigValueLists: async () => {
    const response = await API.get(apiName, "/config", httpClientInit);
    const data = {
      task_status_options: response.data.valueLists["Task Status"].filter(
        (item) => item.value !== "-"
      ),
    };
    return { data };
  },
};

const handleError = (err) => {
  console.log("dp error", err);
  // Network error
  if (!err.response) {
    throw new HttpError(err.message);
  }

  // Error from the server
  throw new HttpError(err.response.data, err.response.status);
};

const diff = (object, base) => {
  return Object.keys(object).reduce((accumulator, key) => {
    if (!isEqual(object[key], base[key])) {
      accumulator[key] = object[key];
    }
    return accumulator;
  }, {});
};

// Returns the url based on resource and method. Takes the params and query as optional, parameters also.
function getResourceURL(resource, method, params, query) {
  switch (method) {
    case "create":
      switch (resource) {
        case "profile":
          return `/me`;
        case "notifications":
          return `/me/${resource}`;
        default:
          return `/${resource}`;
      }
    case "getOne":
    case "update":
    case "delete":
      switch (resource) {
        case "profile":
          return `/me`;
        case "notifications":
          return `/me/${resource}/${params.id}`;
        default:
          return `/${resource}/${params.id}`;
      }
    case "getMany":
      switch (resource) {
        case "profile":
          return `/me`;
        case "notifications":
          return `/me/${resource}?${query}`;
        default:
          return `/${resource}?${query}`;
      }
    case "getManyReference":
    case "getList":
      switch (resource) {
        case "profile":
          return `/me`;
        case "notifications":
          return `/me/${resource}?${stringify(query)}`;
        default:
          return `/${resource}?${stringify(query)}`;
      }
    default:
      return `/${resource}?${stringify(query)}`;
  }
}

export default dataProvider;
