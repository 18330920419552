// This is based on MrHertal react-admin-amplify library

// authentication functions
function getAuthProvider(Auth) {
  // if in auth test mode then return the testAuthProvider instead
  if (process.env.REACT_APP_AUTH_MODE === "test") return testAuthProvider;

  return {
    checkError: async (error) => {
      console.log("authProvider.checkError: ", error);
      return true;
    },
    checkAuth: (params) => {
      console.log("authProvider.checkAuth", params);
      return Auth.currentAuthenticatedUser();
    },
    login: async () => {
      await Auth.federatedSignIn({
        customProvider: process.env.REACT_APP_USER_POOL_SAML_PROVIDER,
      });

      // For federated sign in there needs to be a redirect,
      // this will ensure react-admin will wait for the redirect before doing anything else
      return Promise.reject("Redirecting to sign in page...");
    },
    logout: () => {
      return Auth.signOut();
    },
    getIdentity: async (params) => {
      console.log("authProvider.getIdentity", params);
      const identity = await Auth.currentAuthenticatedUser();

      return {
        contact_id: identity.attributes["custom:contact_id"],
        email: identity.attributes.email,
        last_name: identity.attributes.family_name,
        first_name: identity.attributes.given_name,
      };
    },
    getPermissions: () => Promise.resolve(""),
  };
}

export default getAuthProvider;

const testAuthProvider = {
  checkError: async (error) => {
    console.log("authProvider.checkError: ", error);
    return true;
  },
  checkAuth: async (params) => {
    console.log("authProvider.checkAuth", params);
    return true;
  },
  login: async () => {
    return true;
  },
  logout: async () => {
    return true;
  },
  getIdentity: async (params) => {
    console.log("authProvider.getIdentity.params", params);

    return {
      contact_id: process.env.REACT_APP_TEST_USER_CONTACT_ID,
      email: process.env.REACT_APP_TEST_USER_EMAIL,
      last_name: process.env.REACT_APP_TEST_USER_LAST_NAME,
      first_name: process.env.REACT_APP_TEST_USER_FIRST_NAME,
    };
  },
  getPermissions: () => Promise.resolve(""),
};
