import enUS from "date-fns/locale/en-US";

// Hook that returns a custom locale for formatting dates
function useCustomLocale() {
  return { locale };
}

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee '('MMM d')'",
  other: "MMM d",
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export { useCustomLocale };
