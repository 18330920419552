import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

function useConfigValueLists() {
  const dataProvider = useDataProvider();
  const { data, isLoading, error } = useQuery(
    "configValueLists",
    () => dataProvider.getConfigValueLists().then((res) => res.data),
    { staleTime: 24 * 60 * 60 * 1000 }
  );

  return { data, isLoading, error };
}

export default useConfigValueLists;
