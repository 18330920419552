import { useMediaQuery } from "@mui/material";
import { List, Loading, useGetIdentity } from "react-admin";
import { TaskListDatagrid } from "./TaskListDatagrid";
import { TaskListCustomlist } from "./TaskListCustomlist";

/**
 * Component that renders the TaskList component based on the dropdown.
 * */
function TaskList() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { identity, isLoading } = useGetIdentity();

  if (isLoading) return <Loading />;

  return (
    <>
      <List
        exporter={false}
        pagination={false}
        perPage={9999}
        filter={{
          role_contact_ids: identity.contact_id,
          status__not: "Complete",
        }}
        sort={{ field: "date_end", order: "ASC" }}
      >
        {isSmall ? <TaskListCustomlist /> : <TaskListDatagrid />}
      </List>
    </>
  );
}
export default TaskList;
