import { createElement } from "react";
import {
  useNotify,
  useReference,
  useUpdate,
  useListContext,
  useCreatePath,
} from "react-admin";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import {
  ListItemAvatar,
  LinearProgress,
  ListItemText,
  ListItemButton,
  ListItem,
  Checkbox,
} from "@mui/material";
import taskIcons from "./taskIcons";
import { Link } from "react-router-dom";

export default function Task({ record }) {
  const { isLoading: projectIsLoading, referenceRecord } = useReference({
    id: record.project_id,
    reference: "projects",
  });
  const { refetch } = useListContext();
  const [update] = useUpdate();
  const notify = useNotify();
  const createPath = useCreatePath();

  const markCompleteHandler = () => {
    update(
      "tasks",
      {
        id: record.id,
        data: { status: "Complete" },
        previousData: record,
      },
      {
        onSuccess: () => refetch(),
        onError: (err) => {
          console.log("error trying to complete task", err);
          notify(
            "There was an issue marking the task as complete. Please try again later."
          );
        },
      }
    );
  };

  return (
    <ListItem
      secondaryAction={
        <Checkbox
          onClick={markCompleteHandler}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<CheckCircle />}
        />
      }
    >
      <ListItemButton
        component={Link}
        to={createPath({ resource: "tasks", type: "show", id: record.id })}
      >
        <ListItemAvatar>
          {taskIcons[record.type] ? (
            createElement(taskIcons[record.type])
          ) : (
            <CheckCircle />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={record.name}
          secondary={
            projectIsLoading ? <LinearProgress /> : referenceRecord.name
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
