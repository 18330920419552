import {
  ReferenceField,
  FunctionField,
  DateField,
  SingleFieldList,
  ReferenceArrayField,
  ChipField,
  useRecordContext,
  useShowContext,
  Loading,
  SimpleShowLayout,
} from "react-admin";
import {
  Typography,
  Grid,
  IconButton,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { differenceInDays, parseISO } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { StatusInput } from "../components/StatusInput";
import useConfigValueLists from "../hooks/useConfigValueLists";

export function TaskShowView() {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const navigate = useNavigate();
  const { data } = useConfigValueLists();
  const taskStatusOptions = data?.task_status_options || [];

  if (isLoading) return <Loading />;

  return (
    <>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography variant="h6" component="h2">
          <IconButton aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          {record.name}
        </Typography>
        <ReferenceField
          source="project_id"
          reference="projects"
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FunctionField
            render={(record) => `${record.num} - ${record.name}`}
          />
        </ReferenceField>
      </Box>
      <Box display="flex" flexWrap="wrap-reverse" width="100%" gap="0.5em">
        <Box flex="1 1">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box flex="0 0 25em" maxWidth="100%">
          <Card>
            <CardContent>
              <SimpleShowLayout>
                <StatusInput options={taskStatusOptions} />
                <DateField
                  source="date_end"
                  label="Due Date"
                  sx={() =>
                    new Date(record.date_end) >= new Date()
                      ? {}
                      : {
                          backgroundColor: "red",
                          transform:
                            "scale3d(1.1, 1.1, 1.1) rotate3d(-1, 1, 0.3, -20deg)",
                          padding: "0.5rem",
                          borderRadius: "0.5rem",
                          fontWeight: 600,
                          display: "inline-block",
                        }
                  }
                />
                <DateField source="date_start" label="Start Date" />
                <FunctionField
                  label="Remain"
                  render={(record) =>
                    `${differenceInDays(
                      parseISO(record.date_end),
                      new Date()
                    )} days`
                  }
                />
                <ReferenceArrayField
                  reference="contacts"
                  source="role_contact_ids"
                  label="Assigned"
                >
                  <SingleFieldList>
                    <FunctionField
                      render={(record) => (
                        <ChipField
                          record={{
                            full_name: `${record.first_name} ${record.last_name}`,
                            ...record,
                          }}
                          source="full_name"
                        />
                      )}
                    />
                  </SingleFieldList>
                </ReferenceArrayField>
              </SimpleShowLayout>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
}
