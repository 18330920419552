import { createElement } from "react";
import {
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
} from "react-admin";
import { CheckCircle } from "@mui/icons-material";
import CompleteTaskButton from "./CompleteTaskButton";
import taskIcons from "./taskIcons";
import useConfigValueLists from "../hooks/useConfigValueLists";
import { StatusInput } from "../components/StatusInput";

function TaskListDatagrid() {
  const { data } = useConfigValueLists();
  const task_status_options = data?.task_status_options || [];

  return (
    <>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <FunctionField
          label="Type"
          render={(record) => {
            return taskIcons[record.type] ? (
              createElement(taskIcons[record.type])
            ) : (
              <CheckCircle />
            );
          }}
        />
        <TextField source="name" />
        <ReferenceField source="project_id" reference="projects" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <DateField source="date_end" label="Due date" />
        <StatusInput options={task_status_options} label="Status" />

        <CompleteTaskButton>Mark Complete</CompleteTaskButton>
      </Datagrid>
    </>
  );
}
export { TaskListDatagrid };
