import {
  Description,
  ThumbUpAlt,
  LibraryMusic,
  MovieCreation,
  OndemandVideo,
} from "@mui/icons-material";

const taskIcons = {
  "Rough Approval": ThumbUpAlt,
  "Rough Cut Due": MovieCreation,
  "Final Due": OndemandVideo,
  "Final QC": ThumbUpAlt,
  "Music Cues Due": LibraryMusic,
  "Script Due": Description,
  "Video Due": OndemandVideo,
};

export default taskIcons;
