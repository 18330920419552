import { Layout as RaLayout } from "react-admin";
import AppBar from "./AppBar";
import { ReactQueryDevtools } from "react-query/devtools";
import { CssBaseline } from "@mui/material";
import AddToHomePrompt from "../components/AddToHomePrompt";

function Layout(props) {
  return (
    <>
      <CssBaseline />
      <AddToHomePrompt />
      <RaLayout {...props} appBar={AppBar} sidebar={() => <></>} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default Layout;
