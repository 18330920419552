import { useEffect } from "react";
import {
  useRecordContext,
  useListContext,
  useUpdate,
  useNotify,
} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";

function CompleteTaskButton({ value = "Complete", children }) {
  const { refetch } = useListContext();
  const record = useRecordContext();

  const [update, { isLoading, error }] = useUpdate();
  const notify = useNotify();

  useEffect(() => {
    if (error) {
      console.log("error", error.message);
      notify(error.message, "warning");
    }
  }, [error, notify]);

  const markCompleteHandler = () => {
    update(
      "tasks",
      {
        id: record.id,
        data: { status: value },
        previousData: record,
      },
      { onSuccess: refetch }
    );
  };

  return (
    <LoadingButton loading={isLoading} onClick={markCompleteHandler}>
      {children}
    </LoadingButton>
  );
}

export default CompleteTaskButton;
